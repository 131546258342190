import axios from "axios"
const api = (token) => 
  axios.create({
    baseURL: "https://route-optimizer-api.vercel.app",
    headers: {
      Authorization: `Bearer ${token}`,
    }
});
export const loginUser = async (username, password) => {
    try {
      const response = await axios.post(
            `https://route-optimizer-api.vercel.app/auth/login`,
            { username, password },
            { withCredentials: true, headers: {'Content-Type': 'application/json'} },
      );    
      const { token } = response.data;
      localStorage.setItem('token', token);
      return response;
    } catch (error) {
      return { message: error?.response?.data?.message };
    }
}

export const validUser = async () => {
    try {
      const token = localStorage.getItem('token');
      return await api(token).get(`/auth/valid`);
    } catch (error) {
      return {}
    }
  };