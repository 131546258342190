import axios from 'axios'
const api = (token) => 
    axios.create({
      baseURL: "https://route-optimizer-api.vercel.app",
      headers: {
        Authorization: `Bearer ${token}`,
      }
});

export const fetchOptimizedRoutes = async (startPoint, addresses, numVehicles) => {
  try{
    const token = localStorage.getItem("token");
    
    startPoint = {
      ...startPoint,
      coordinates: [startPoint.coordinates[1], startPoint.coordinates[0]]
    };
    addresses = addresses.map(address => {
      return {
        ...address,
        coordinates: [address.coordinates[1], address.coordinates[0]] 
      };
    });
    
    const {data} = await api(token).post('/maps/optimize', { startPoint, addresses, numVehicles });
    return data;
  }catch(err){
    return { error: err.response?.data, message: err.response?.data?.message };
  }
}