import React from 'react'
import "../styles/Loader.css"

const Loader = ({opacity}) => {
  return (
    <section class="wrapper" style={{opacity: opacity}}>
        <div class="card">
            <div class="loading">
            <span ></span>
            <span ></span>
            <span ></span>
            </div>
        </div>
    </section>
  )
}

export default Loader