import { useState, useEffect, useRef } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const Autocomplete = ({ onSelect, placeholder = "Direccion", value = "" }) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } = usePlacesService({
    apiKey: 'AIzaSyDsYaElHqKEYbjpjglRk37iQBNv-UHgftA',
    options: {
      componentRestrictions: { country: 'AR' },
      types: ['address'],
      fields: ['formatted_address', 'geometry'],
    },
    language: 'es',
    debounce: 1000,
    sessionToken: true,
  });
  const [inputValue, setInputValue] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  const [inputRect, setInputRect] = useState(null);
  const dropdownRef = useRef(null);

  const handleInputChange = (evt) => {
    setInputValue(evt.target.value);

    if (evt.target.value.length > 8) {
      getPlacePredictions({ input: evt.target.value });
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  }

  const handleSelect = (item) => {
    placesService.getDetails(
      {
        placeId: item.place_id,
        fields: ['formatted_address', 'geometry'],  
      },
      (placeDetails, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && placeDetails) {
          const { lat, lng } = placeDetails.geometry.location;
          setInputValue(placeDetails.formatted_address);
          onSelect({
            address: placeDetails.formatted_address,
            lng: lng(),
            lat: lat(),
          });
          setShowDropdown(false);
        }
      }
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target) &&
          dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (showDropdown && inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setInputRect(rect);  // Save the input's size and position
    }
  }, [showDropdown]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div style={{ width: "100%" }}>
      <input
        ref={inputRef}
        className="input"
        value={inputValue}
        placeholder={placeholder}
        onChange={e => handleInputChange(e)}
        onFocus={() => setShowDropdown(true)} 
        loading={isPlacePredictionsLoading}
      />
      {showDropdown && !isPlacePredictionsLoading && placePredictions.length > 0 && inputValue.length > 8 &&(
        <ul
        ref={dropdownRef}
          style={{
            listStyleType: "none",
            padding: '0.25rem',
            margin: 0,
            position: "fixed",
            width: `${inputRect?.width}px`,
            top: `${inputRect?.bottom}px`,
            left: `${inputRect?.left}px`,
            backgroundColor: "white",
            border: "1px solid #000000",
            height: "auto",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 999,
          }}
        >
          {placePredictions.map((item) => (
            <li
              key={item.place_id}
              onClick={() => handleSelect(item)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #eee",
              }}
            >
              {item.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;