import '../styles/Dashboard.css';
import Spinner from './Spinner';
import { handleError } from '../utils/toastNotifications';
import AutoComplete from './AutoComplete';
const Form = ({
    startPoint,
    setStartPoint,
    routes,
    handleAddRoute,
    handleStartPointChange,
    handleRouteChange,
    handleRemoveRoute,
    workers,
    handleWorkerChange,
    isOptimized,
    handlePlanificar,
    handleReset,
    optimizing,
    formRef,
    invalidAddresses
  }) => {

    const validateFields = () => {
      const maxRoutes = workers * 30;
      if (!startPoint.address.trim()) {
        handleError('Punto de partida no puede estar vacío.');
        return false;
      }
  
      if (routes.length > maxRoutes) {
        handleError(`No se pueden agregar más de ${maxRoutes} direcciones para ${workers} operario(s).`);
        return false;
      }

      for (let i = 0; i < routes.length; i++) {
        if (!routes[i].address.trim()) {
          handleError(`La dirección ${i + 1} no puede estar vacía.`);
          return false;
        }
      }
  
      if (routes.length < workers) {
        handleError(`Debe haber al menos una dirección por cada operario. Actualmente tienes ${routes.length} dirección(es) para ${workers} operario(s).`);
        return false;
      }

      const uniqueRoutes = new Set(routes.map(route => route.address.trim().toLowerCase()));
      if (uniqueRoutes.size !== routes.length) {
        handleError('Las direcciones duplicadas no están permitidas.');
        return false;
      }
  
      return true;
    };
  
    const onHandlePlanificar = () => {
      if(validateFields()) {
        handlePlanificar();
      }
    }

    const handleStartPointSelect = (result) => {
      if (result && result?.address && result?.lat && result?.lng) {
        setStartPoint({
          address: result.address,
          coordinates: [result.lng, result.lat], 
        });
      }
    };
  
    const handleRouteSelect = (index, result) => {
      if (result && result?.address && result?.lat && result?.lng) {
        handleRouteChange(index, {
          address: result.address,
          coordinates: [result.lng, result.lat], 
        });
      }
    };

    return (
      <div className="form">
        <div className='inner' ref={formRef}>
          <div className='input-container'>
            <p>Punto de partida:</p>
            <div style={{width: '100%'}}>
            <AutoComplete
                onSelect={handleStartPointSelect}
                value={startPoint.address}
              />
            </div>
            <button className="removeButton disabled">
              X
          </button>
          </div>

            {routes.map((route, index) => (
            <div key={index} className='input-container'>
                <p>
                  Dirección {index + 1}
                </p>
                <div style={{ position: 'relative', width: '100%' }}>
                <AutoComplete
                  onSelect={(result) => handleRouteSelect(index, result)} 
                  value={route.address}
                />
                </div>                
                <button className="removeButton" onClick={() => handleRemoveRoute(index)}>
                X
                </button>
            </div>
            ))}
        </div>
        <button className="addButton" onClick={handleAddRoute}>
        + Dirección
        </button>
        <br />
        <label>
          Cantidad de operarios
          <select
            className="select"
            value={workers}
            onChange={handleWorkerChange}
          >
            {[...Array(10).keys()].map((number) => (
              <option key={number + 1} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </label>
        <br />
        <div className='btn-container'>
          {optimizing && <Spinner />}
          <button
            className={`planButton ${routes.length === 0 ? 'disabled' : ''}`}
            onClick={isOptimized ? handleReset : onHandlePlanificar}
            disabled={routes.length === 0 || optimizing}
          >
            {isOptimized ? 'Reiniciar' : 'Planificar'}
          </button>
        </div>
      </div>
    );
  };
  
export default Form;  
