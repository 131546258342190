// src/App.js
import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Navigate to="/optimizer"/>}/>
        <Route exact path='/optimizer' element={<Dashboard/>}/>
        <Route exact path='/login' element={<Login/>}/>
      </Routes>
  </BrowserRouter>  );
}

export default App;
