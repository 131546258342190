import React, { useState, useMemo, useCallback, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import '../styles/Dashboard.css';
import Navbar from './Navbar';
import { useUserValidation } from '../hooks/useUserValidation';
import { fetchOptimizedRoutes } from '../api/mapbox'
import redIcon from '../assets/marker-icon-2x-red.png';
import greenIcon from '../assets/marker-icon-2x-green.png';
import blueIcon from '../assets/marker-icon-2x-blue.png';
import yellowIcon from '../assets/marker-icon-2x-yellow.png';
import violetIcon from '../assets/marker-icon-2x-violet.png';
import orangeIcon from '../assets/marker-icon-2x-orange.png';
import goldIcon from '../assets/marker-icon-2x-gold.png';
import grayIcon from '../assets/marker-icon-2x-grey.png';
import blackIcon from '../assets/marker-icon-2x-black.png';
import pinkIcon from '../assets/marker-icon-2x-pink.png';
import Form from './Form';
import GoToTop from './GoToTop';
import Loader from './Loader'
import { handleError } from '../utils/toastNotifications';
import Logout from './Logout';
const Map = React.lazy(() => import('./Map'));
const RouteDetails = React.lazy(() => import('./RouteDetails'));

function Dashboard() {
  const loading = useUserValidation();
  const [routes, setRoutes] = useState([]);
  const [workers, setWorkers] = useState(1);
  const [startPoint, setStartPoint] = useState({address: '', coordinates: ''});
  const [plan, setPlan] = useState(false);
  const [isOptimized, setIsOptimized] = useState(false);
  const [optimizing, setOptimizing] = useState(false);
  const [optimizedRoutes, setOptimizedRoutes] = useState([]);
  const [invalidAddresses, setInvalidAddresses] = useState([]);
  const formRef = useRef(null);
  const lowerContainerRef = useRef(null);
  
  const colors = useMemo(() => [
    '#CB2B3E', '#2AAD27', '#2A81CB', '#a8a427', '#9C2BCB', '#CB8427', '#ffcc00', '#7B7B7B', '#3D3D3D', "rgb(244, 85, 197)"
  ], []);
  
  const operatorIcons = useMemo(() => [
    redIcon, greenIcon, blueIcon, yellowIcon, violetIcon, orangeIcon, goldIcon, grayIcon, blackIcon, pinkIcon
  ], []);

  const optimizeRoutes = useCallback(async () => {
    setOptimizing(true);
    try {
      const response = await fetchOptimizedRoutes(startPoint, routes, workers);
      if (response.error?.error) {
        const message = response.error.message === "Route exceeds maximum distance limitation"
          ? "Distancia maxima superada! (10.000km) verifique las direcciones!"
          : response.error;
        handleError(message);
        if (response.error.failedAddresses) {
          setInvalidAddresses(response.error.failedAddresses);
          handleError(response.error.error);
        }
        return;
      }
      const routesWithDetails = response.routes.map((routeDetails, index) => {
        const stops = routeDetails.optimizedCoordinates.map((coord, i) => {
          let addressLabel = null;
      
          if (i > 0 && i < routeDetails.optimizedCoordinates.length - 1) {
            addressLabel = `Stop ${i}`;
          }
      
          return {
            position: coord,
            label: i === 0 || i === routeDetails.optimizedCoordinates.length - 1 ? 'Inicio/Fin' : addressLabel,
            address: routeDetails.originalAddresses[i]?.address.address,
          };
        });
      
        return {
          path: routeDetails.routeSteps, // Path for drawing polylines
          color: colors[index % colors.length],
          icon: operatorIcons[index % operatorIcons.length],
          stops, // Markers for each stop
          distance: (routeDetails.totalDistance / 1000).toFixed(1),
        };
      });
      
      setOptimizedRoutes(routesWithDetails);
      setPlan(true);
      setIsOptimized(true);
  
      setTimeout(() => {
        lowerContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      
    } catch (error) {
      console.error('Error optimizing routes:', error);
      handleError(error);
    } finally {
      setOptimizing(false);
    }
  }, [startPoint, routes, workers]);
  
  const handleAddRoute = () => {
    setRoutes([...routes, { address: '', coordinates: '' }]);
    setIsOptimized(false);
    setTimeout(() => {
      formRef.current.scrollTo({
        top: formRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }, 0);
  };

  const handleRouteChange = (index, value) => {
    const newRoutes = [...routes];
    newRoutes[index] = value;
    setRoutes(newRoutes);
    setIsOptimized(false);
  };

  const handleStartPointChange = (value) => {
    setStartPoint(value);
    setIsOptimized(false);
  };

  const handleRemoveRoute = (index) => {
    setRoutes(prevRoutes => prevRoutes.filter((_, i) => i !== index));
    setIsOptimized(false);
  };

  const handleWorkerChange = (event) => {
    setWorkers(Number(event.target.value));
    setIsOptimized(false);
  };

  const handlePlanificar = async () => {
    await optimizeRoutes();
  };

  const handleReset = () => {
    setIsOptimized(false);
    setPlan(false);
    setOptimizedRoutes([]);
    setStartPoint('');
    setWorkers(1);
    setRoutes([]);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container">
      <Logout/>
      <div className="upper">
        <Form
          startPoint={startPoint}
          setStartPoint={setStartPoint}
          routes={routes}
          handleAddRoute={handleAddRoute}
          handleStartPointChange={handleStartPointChange}
          handleRouteChange={handleRouteChange}
          handleRemoveRoute={handleRemoveRoute}
          workers={workers}
          handleWorkerChange={handleWorkerChange}
          isOptimized={isOptimized}
          handlePlanificar={handlePlanificar}
          handleReset={handleReset}
          optimizing={optimizing}
          setWorkers={setWorkers}
          formRef={formRef}
          invalidAddresses={invalidAddresses}
        />
      </div>
      <React.Suspense fallback={<Loader />}>
          {plan && optimizedRoutes.length > 0 && (
              <div className="lower" ref={lowerContainerRef}>
                  <Navbar/>
                  <div className="map-container">
                      <Map optimizedRoutes={optimizedRoutes} operatorIcons={operatorIcons} />
                  </div>
                  <RouteDetails optimizedRoutes={optimizedRoutes} colors={colors} />
              </div>
          )}
      </React.Suspense>
      <ToastContainer />
      <GoToTop />
    </div>
  );
}

export default Dashboard;