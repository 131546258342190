import React from 'react'
import { useNavigate } from 'react-router-dom';
import LogoImg from '../assets/BeepsLogo.webp';
import BeepsImg from '../assets/Beeps.webp'
import '../styles/Navbar.css';


const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className='navbar'>
      <div 
        className='logo'
        onClick={() => navigate('/')}
      >
        <img className='logo-img' src={LogoImg} />
        <img className='beeps-img' src={BeepsImg} />
      </div>
    </nav>
  )
}

export default Navbar
