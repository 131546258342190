import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const handleError = (err) => {
    toast.error(err, {
      position: "bottom-left",
    });
  }

  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  }

  export {handleError, handleSuccess};