import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validUser } from '../api/auth';
import { handleError } from '../utils/toastNotifications';

export const useUserValidation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateUser = async () => {
      try {
        const { data } = await validUser();

        if (!data) {
          navigate('/login');
        }
      } catch (error) {
        navigate('/login');
      }finally{
        setLoading(false);
      }
    };

    validateUser();
  }, [navigate]);

  return loading;
};
