import React from 'react'
import "../styles/Logout.css"
const Logout = () => {
    const [isHovered, setIsHovered] = React.useState(false);
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }
  return (
    <div className='logout-container'
    onClick={handleLogout}
    >
        <span className='material-symbols-outlined'>
        logout
        </span>
        <p>Logout</p>
    </div>
  )
}
export default Logout