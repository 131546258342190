import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../api/auth';
import { ToastContainer } from 'react-toastify';
import { handleSuccess, handleError } from '../utils/toastNotifications';
import Image from "../assets/logo-amarillo-compu.webp";
import Navbar from './Navbar';
import '../styles/Login.css';
import Loader from './Loader';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await loginUser(username, password);
        console.log(response);
        if(response.status === 200){
            localStorage.setItem('token', response.data.token);
            handleSuccess("Bienvenido");
            setIsLoading(false);
            setTimeout(() => {
                navigate('/');
            }, 1000);
        } else {
            handleError(response.message);
        }
        setIsLoading(false);
        setUsername("");
        setPassword("");
    };

    return (
        <div 
            className="login-container"
            style={{ backgroundImage: `url(${Image})` }}
        >
            <Navbar />
            {isLoading && <div className='loader-wrapper'><Loader opacity={0.5}/></div>}

            <div className="login-content">
                <div className="input-group">
                    <label>Usuario</label>
                    <input
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="input-field"
                    />
                </div>
                <div className="input-group">
                    <label>Contraseña</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="input-field"
                    />
                    <span className='material-symbols-outlined' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'visibility_off' : 'visibility'}
                    </span>
                </div>
                <button
                    disabled={username.length === 0 || password.length === 0}
                    className="login-button"
                    onClick={(e) => handleLogin(e)}
                >
                    Ingresar
                </button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Login;